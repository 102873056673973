/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
  .link{
    @apply cursor-pointer hover:underline
  }
  .button {
    @apply text-xs md:text-sm bg-gradient-to-b from-red-400 to-red-500 border-red-400 rounded-sm focus:outline-none focus:ring-2 focus:ring-red-600
  }
}

html {
  overflow: hidden;
}