#scroll::-webkit-scrollbar {
    display: none;
  }

#navbar {
    /* position: fixed; */
    top: 0;
    width: 100%;
    transition: top 0.3s;
}

#container {
  transition: 0.2s;
}